import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useLogoutService = () => {
  return useMutation(() =>
    servicesUtils.getService("/accounts/logout/", {
      next: window.location.href,
    })
  );
};

export default useLogoutService;
